<template>
  <div class="user-info">
    <div class="user-info-cnt">
      <div class="user-tab">
        <span
          :class="tab==1? 'active':''"
          @click="tab=1"
        >基本资料</span>
        <span
          :class="tab==2? 'active':''"
          @click="tab=2"
        >修改密码</span>
      </div>
      <div class="user-info-body">
        <div v-show="tab==1">
          <div class="user-logo">
            <div class="user-avatar">
              <el-avatar
                style="width: 130px;height:130px; cursor: pointer;"
                :src="userInfo.avatar?userInfo.avatar:require('@/assets/image/default-avatar.png')"
                :key="userInfo.avatar"
              >
              </el-avatar>
              <div class="upload-avatar">
                <el-upload
                  style="display: inline-block;"
                  :on-success="(response) => handleUpload(response)"
                  :show-file-list="false"
                  :headers="headers"
                  :file-list="fileList"
                  :before-upload="beforeAvatarUpload"
                  action="/api/oss/file/upload2"
                >
                  <i class="el-icon-upload"></i>
                </el-upload>
                <!-- <i class="el-icon-delete" @click="clearAvatar"></i> -->
              </div>
            </div>
          </div>
          <ul class="user-info-desc">
            <li>
              <label>姓名</label><span>{{userInfo.realname}}</span>
            </li>
            <li v-if="userInfo.role && userInfo.role.includes(this.$role.STUDENT)">
              <label>学号</label><span>{{userInfo.sno}}</span>
            </li>
            <li>
              <label>手机号</label><span>{{userInfo.phone}}</span>
            </li>
            <li>
              <label>省/市</label><span>{{userInfo.city}}</span>
            </li>
            <li>
              <label>区/县</label><span>{{userInfo.area}}</span>
            </li>
            <li>
              <label>学校</label><span>{{userInfo.schools}}</span>
            </li>
            <li>
              <label>年级/班级</label><span>{{userInfo.grade}}{{userInfo.sclass}}</span>
            </li>
            <li>
              <label>性别</label><span>{{userInfo.sex==1?'男':'女'}}</span>
            </li>
            <li>
              <label>出生日期</label><span>{{userInfo.birthday?userInfo.birthday:'无'}}</span>
            </li>
          </ul>
        </div>
        <forget-pwd v-show="tab==2"></forget-pwd>
      </div>
    </div>
  </div>
</template>

<script>
import { changeAvatar } from "@/api/user"
import Vue from 'vue'
import { getUserInfo as getUserBaseInfo } from '@/utils/auth';
import ForgetPwd from '@/views/User/ForgetPwd'
import { ACCESS_TOKEN } from '@/store/mutation-types';
const token = Vue.ls.get(ACCESS_TOKEN);

export default {
  name: 'User-Center',
  components: { ForgetPwd },
  data() {
    return {
      tab: 1,
      fileList: [],
      headers: {
        'X-Access-Token': token,
      },
    };
  },
  watch: {
    showDialog(value) {
      if (value) {
        this.dialogForm.nickname = this.userInfo.realname;
      }
    }
  },
  created() {
    // this.$store.dispatch('InitUserRole')
  },
  beforeRouteEnter(to, from, next) {
    if (!getUserBaseInfo()) next(`/login?redirect_uri=${location.href}`)
    else next()
  },
  computed: {
    userInfo: function () {
      let u = this.$store.state.userInfo
      return (u && u.id) ? u : getUserBaseInfo();
    }
  },
  methods: {
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    clearAvatar() {
      this.userInfo.avatar = ''
      this.$store.dispatch('ChangeAvatar', "");
    },
    handleUpload(res) {
      // console.log("上传成功：", res)
      if (res.success) {
        //this.$set(this.userInfo, "avatar", res.message)
        this.$store.dispatch('ChangeAvatar', res.message);
        changeAvatar({ url: res.message, userId: this.userInfo.id })
      }
      else this.$message.error('上传失败！')
    },
    closeDialog() {
      // console.log(this.$refs.dialogForm)
      this.$refs.dialogForm.resetFields();
    }
  },
};
</script>

<style>
.user-info .el-upload-dragger {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: transparent;
  border: 0px;
}
.user-info .el-upload-dragger .el-icon-upload {
  font-size: 30px;
  color: #ffffff;
}

.user-info .upload-avatar .el-icon-delete,
.user-info .upload-avatar .el-icon-upload {
  margin: 0px 3px;
  font-size: 28px;
  color: #ffffff;
}
.user-info .upload-avatar .el-icon-delete {
  font-size: 23px;
}
</style>
<style lang="scss" scoped>
.user-info {
  background: url(~@/assets/image/user/bg.jpg) no-repeat;
  background-size: 100% 100%;
  height: 100%;
  //background: skyblue;
  position: relative;

  .user-info-cnt {
    display: flex;
    width: 700px;
    // height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-365px, -300px);

    .user-tab {
      span {
        display: block;
        width: 32px;
        text-align: center;
        height: 113px;
        padding: 15px 10px;
        position: relative;
        top: 50px;
        background: rgb(255 255 255 / 20%);
        border-radius: 5px 0px 0px 5px;
        margin-bottom: 5px;
        line-height: 1.3;
        color: #f3f3f3;
        cursor: pointer;
        transition: all linear 0.25s;
      }
      span.active {
        background: orange;
        color: #ffffff;
      }
    }
  }

  .user-info-body {
    background: #ffffff;
    border-radius: 12px;
    width: 730px;
    // height: 600px;
    padding: 30px 50px;

    .user-logo {
      text-align: center;
      position: relative;

      .upload-avatar {
        position: absolute;
        top: -1px;
        left: 219px;
        width: 130px;
        height: 130px;
        line-height: 130px;
        border-radius: 130px;
        background: rgba(0, 0, 0, 0.25);
        display: none;
      }
      // .show{
      //   display: block
      // }
      // .hide{
      //   display: none;
      // }
      .user-avatar {
        width: 130px;
        height: 130px;
        border-radius: 130px;
        margin: 0px auto;
        cursor: pointer;
        &:hover {
          .upload-avatar {
            display: block;
          }
        }
      }
    }
    .user-info-desc {
      li {
        font-size: 16px;
        height: 50px;
        line-height: 50px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f2f2f2;

        span {
          color: #aaaaaa;
        }
      }
    }
  }
}
</style>
