<template>
  <div class="forget-pwd">
    <div class="login-form-item">
        <el-form :model="form" :rules="forgetPwdRules" ref="forgetPwdForm" label-width="80">
            <el-form-item prop="phone" label="请输入手机号">
                <el-input v-model="form.phone" disabled placeholder="请输入手机号码找回密码"></el-input>
            </el-form-item>
            <el-form-item prop="smsCode" label="请输入短信验证码">
                <div style="clear: both;">
                    <el-input v-model="form.smsCode" placeholder="短信验证码" style="width: calc(100% - 170px); margin-right: 20px;" @keyup.enter.native="postPhonePwdOne"></el-input>
                    <el-button type="primary" @click="sendCode(4)" style="float: right; width: 150px;" :disabled="isOkForgetPwdPhone || times>0" :loading="sendLoading"><span v-if="times>0">{{times}}s后可再次发送</span><span v-else-if="!sendLoading">发送验证码</span><span v-else>发送中...</span></el-button>
                    <p v-if="showNoRegTip" class="info-1">检测到您未注册，请点击<a href="javascript:;" @click="toReg">注册</a></p>
                </div>
            </el-form-item>
            <el-form-item prop="userId"  label="请选择需要修改密码的账号" v-show="isMutiAccount">
                <el-select v-model="form.userId" placeholder="请选择账号" style="width: 100%" popper-class="ma-select">
                <el-option :label="item.realname" :value="item.id" v-for="item in accountList" :key="item.id" >
                    <div class="account-item">
                    <el-image :src="item.avatar" class="user-logo">
                        <div slot="error" class="image-slot">
                        <img src="~@/assets/image/default-avatar.png"/>
                        </div>
                    </el-image>
                    <span>{{item.realname}}</span>
                    </div>
                </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="password" label="请输入新密码">
                <el-input type="password" v-model="form.password" placeholder="密码(6-12位)至少含两种字符（数字、字母、特殊字符）"></el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword" label="请重新输入新密码">
                <el-input type="password" v-model="form.confirmPassword" placeholder="请重新输入新密码"></el-input>
            </el-form-item>
        </el-form>
    </div>
    <el-button type="primary" style="width: 100%;" @click="submit">修改密码</el-button>
    </div>
</template>

<script>
import { sendCode, forgetPwd, changePwd} from "@/api/user"
import { getUserInfo as getUserBaseInfo } from '@/utils/auth';

export default {
    name: 'ForgetPwd',
    data() {
        return {
            form :{},
            sendLoading: false,
            times: 0,
            showNoRegTip: false,
            isMutiAccount: false,
            accountList: [],
            forgetPwdRules: {
                    phone: [
                    { required: true, message: '请输入账号', trigger: 'blur' },
                    // { validator: phoneValidator, trigger: 'blur' }
                    ],
                    smsCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    ],
                    password: [
                        { required: true, message: '请输入新密码', trigger: 'blur' },
                        { validator: (rule, value, callback)=>{
                            if(!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{6,12}$/.test(value)) callback("密码(6-12位)至少含两种字符（数字、字母、特殊字符）")
                            else callback()
                        }}
                    ],
                    confirmPassword: [
                        { required: true, message: '请重新输入新密码', trigger: 'blur' },
                        { validator: (rule, value, callback) => {
                        if (value == '') {
                        callback(new Error('请重新输入新密码'));
                        } else if(value !== this.form.password) {
                        callback(new Error('两次密码不一致'));
                        } else {
                        callback();
                        }
                    }, trigger: 'blur' }
                    ],
            },
        };
    },
    computed: {
        isOkForgetPwdPhone() {
        return !(/^1[0-9]{10}$/.test(this.form.phone));
        // return !(/^1[0-9]{10}$/.test(this.regForm.phone) && this.regForm.verCode);
        },
        userInfo: function() {
            return getUserBaseInfo();
        },
    },
    created(){
        this.isMutiAccount = true
        if(this.userInfo){
            this.form.phone = this.userInfo.phone
            this.form.userId = this.userInfo.id
            this.isMutiAccount = false
        }
    },
    methods:{
        submit() {
            this.form.smsCode = this.form.smsCode.trim()
            this.form.password = this.form.password.trim()
            this.form.confirmPassword = this.form.confirmPassword.trim()
            this.$refs.forgetPwdForm.validate( isOk => {
                if (isOk) {
                    this.form.id = this.userInfo.id
                    changePwd(this.form).then(res => {
                    if (res.success) {
                        this.$notify({
                            title: '系统提示',
                            message: res.message || '修改密码成功',
                            type: 'success'
                        });
                        //this.toLogin();
                    } else {
                        if (res.code == 500 && res.message == '您填写的手机号不存在') {
                        this.showNoRegTip = true;
                        } else {
                        this.$notify({
                            title: '系统提示',
                            message: res.message || '验证失败：未知错误',
                            type: 'warning'
                        });
                        }
                    }
                    })
                }
            })
            },
        sendCode(type) {
            if (this.times == 0) {
                this.sendLoading = true;
                sendCode({
                    phone: this.form.phone,
                    scene: 5
                }).then(res => {
                    this.sendLoading = false;
                    if (res.success) {
                        this.$notify({
                        title: '系统提示',
                        message: res.message || '发送成功',
                        type: 'success'
                        });
                        this.times = 59;
                        this.setI = setInterval(() => {
                        this.times -=1;
                        if(this.times == 0) {
                            clearInterval(this.setI);
                        }
                        }, 1000);
                } else {
                    this.$notify({
                    title: '系统提示',
                    message: res.message,
                    type: 'warning'
                    });
                }
                }, error => {
                    this.sendLoading = false;
                    this.$notify({
                        title: '系统提示',
                        message: error ,
                        type: 'warning'
                    });
                })
            }
            },
    }
}
</script>

<style lang="scss" scoped>
.forget-pwd{
    margin-top: 30px;
}
</style>